import React from 'react';
import ReactDOM from 'react-dom';

import './fonts/Normal-Regular.ttf'
import './fonts/Normal-Regular.otf'
import './fonts/Normal-ExtraBold.ttf'
import './fonts/Normal-ExtraBold.otf'
import './images/AjaxWebsiteTopImage.jpg'
import './images/BlueMountainBottomImg.jpg'


import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';



ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
